import React, { useState, useEffect, useRef } from "react"

import Layout from "../../components/layout"
import SEO from "../../components/seo"

import HeaderLogo from "../../components/headerLogo/headerLogo"
import HeaderBack from "../../components/headerBack/headerBack"
import HeaderCart from "../../components/headerCart/headerCart"

import ProductV2 from "../../components/product/productV2"

function ProductShopify() {

  const shopifyProductId = '6128088449191'
  const base64ProductId = Buffer.from('gid://shopify/Product/' + shopifyProductId).toString('base64')

  const sizes = {
    "Cest": [
      "",
      "36 1/4 / 92",
      "38.5 / 98",
      "41 1/4 / 106",
      "44 / 112",
      "",
      "",
      "",
      ""
    ],
    "Length": [
      "",
      "24 1/2 / 62",
      "25 1/2 / 65",
      "26 1/4 / 67",
      "27 / 69",
      "",
      "",
      "",
      ""
    ],
    "Sleeves": [
      "",
      "24 3/4 / 63",
      "25 / 64",
      "25 1/2 / 65",
      "26 / 66",
      "",
      "",
      "",
      ""
    ]
  }


  return (
    <Layout>
      <SEO title="Shop - Recycled Cashmere Sweatshirt Woman" />

      <ProductV2 productId={base64ProductId} sizes={sizes} />
    </Layout>
  )

} export default ProductShopify